<template>
  <div
    class="xflex xborder-[1px] !xborder-opacity-100 !xborder-[#d1d1d1] xp-[1px] xrounded-[0.25em] xflex-row xjustify-between xitems-center"
  >
    <div class="xflex xw-[50px] xflex-col xjustify-center xitems-center">
      <icon-tooltip
        color="#212121"
        tip="Previous Week"
        icon="mdi-chevron-left mdi-36px"
        :disabled="noPast && isBeforePrev"
        clickable
        @click="prev"
      >
      </icon-tooltip>
    </div>
    <div class="xflex xflex-col xflex-1 xjustify-center xitems-center">
      <v-menu
        ref="rangemenu"
        v-model="rangemenu"
        :close-on-content-click="false"
        :return-value.sync="range"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="display"
            readonly
            class="weekly-display xtext-center"
            dense
            prepend-icon="mdi-calendar-blank-outline"
            hide-details="auto"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          class="kirby-week-picker"
          range
          :min="min"
          :max="max"
          v-model="range"
          no-title
          show-adjacent-months
          color="primary"
          @click:date="handleChange"
        >
        </v-date-picker>
      </v-menu>
    </div>
    <div class="xflex xw-[50px] xflex-col xjustify-center xitems-center">
      <icon-tooltip
        color="#212121"
        tip="Next Week"
        icon="mdi-chevron-right mdi-36px"
        :disabled="noFuture && isFutureNext"
        clickable
        @click="next"
      >
      </icon-tooltip>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      default: () => [
        moment().startOf("week").format("YYYY-MM-DD"),
        moment().endOf("week").format("YYYY-MM-DD"),
      ],
    },
    noFuture: { type: Boolean, default: false },
    noPast: { type: Boolean, default: false },
  },
  data() {
    return {
      format: "YYYY-MM-DD",
      range: null,
      rangemenu: null,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.range =
          val && val.length
            ? val
            : [
                moment().startOf("week").format(this.format),
                moment().endOf("week").format(this.format),
              ];
      },
      deep: true,
      immediate: true,
    },
    range: {
      handler: function (val) {
        this.$emit("input", val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    display() {
      return `${moment(this.left, this.format).format("ll")} ⟹ ${moment(
        this.right,
        this.format
      ).format("ll")}`;
    },
    left() {
      return this.range && this.range.length
        ? moment(this.range[0], this.format).startOf("week").format(this.format)
        : moment().startOf("week").format(this.format);
    },
    right() {
      return this.range && this.range.length
        ? moment(this.range[1], this.format).endOf("week").format(this.format)
        : moment().endOf("week").format(this.format);
    },
    nextWeek() {
      return this.right
        ? [
            moment(this.right, this.format)
              .add(1, "day")
              .startOf("week")
              .format(this.format),
            moment(this.right, this.format)
              .add(1, "day")
              .endOf("week")
              .format(this.format),
          ]
        : null;
    },
    prevWeek() {
      return this.left
        ? [
            moment(this.left, this.format)
              .subtract(1, "day")
              .startOf("week")
              .format(this.format),
            moment(this.left, this.format)
              .subtract(1, "day")
              .endOf("week")
              .format(this.format),
          ]
        : null;
    },
    isBeforePrev() {
      return this.prevWeek
        ? moment(this.prevWeek[0], this.format).isBefore(this.min)
        : false;
    },
    isFutureNext() {
      return this.nextWeek
        ? moment(this.nextWeek[1], this.format).isAfter(this.max)
        : false;
    },
    min() {
      return this.noPast ? moment().startOf("week").format(this.format) : null;
    },
    max() {
      return this.noFuture ? moment().endOf("week").format(this.format) : null;
    },
  },
  methods: {
    async handleChange(val) {
      const value = val
        ? [
            moment(val, this.format).startOf("week").format(this.format),
            moment(val, this.format).endOf("week").format(this.format),
          ]
        : null;
      this.$refs.rangemenu.save(value);
      await this.emit("change", value);
    },
    async prev() {
      await this.emit("change", this.prevWeek);
      this.range = this.prevWeek;
    },
    async next() {
      await this.emit("change", this.nextWeek);
      this.range = this.nextWeek;
    },
    emit(eventName, value) {
      return new Promise((resolve, reject) => {
        this.$emit(eventName, value);
        this.$nextTick(resolve);
      });
    },
  },
};
</script>

<style lang="scss">
.weekly-display {
  min-width: 230px !important;

  .v-input__slot {
    &::before {
      border: none !important;
    }
  }
  .display-text {
    font-weight: 600;
    color: #6a6569;
  }
  input {
    box-shadow: none !important;
    border: none !important;
    text-align: center !important;
    padding: 5px !important;
  }
}
.kirby-week-picker {
  .v-date-picker-table {
    table {
      tbody {
        tr {
          &:hover {
            background: #e0e0e0 !important;
            td {
              button {
                color: #6a2c91;
                &.v-btn--active {
                  color: #ffffff !important;
                  &.v-btn--disabled {
                    color: #ffffff !important;
                  }
                }
              }
            }
          }
          td {
            button {
              &.v-btn--active {
                color: #ffffff !important;
                &.v-btn--disabled {
                  color: #ffffff !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
